(function ($) {
    "use strict"; // Start of use strict

    const url = window.location;

    let element = $(".nav-item a").filter(function () {
        return this.href == url || url.href.indexOf(this.href) == 0;
    });

    if (element.hasClass("collapse-item")) {
        element.addClass("active");
    }

    $(element.parents()).each(function () {
        if (this.className.indexOf("nav-item") != -1) {
            $(this).addClass("active");
        }
        if (this.className.indexOf("collapse") != -1) {
            $(this).addClass("show");
            $(this).siblings(".nav-link").removeClass("collapsed");
        }
    });

    $("input[required], select[required], textarea[required]")
        .siblings("label")
        .addClass("required");

    $("div.alert-close").delay(10000).fadeOut(10000);

    $(".btn-print").on("click", printPage);

    $(".btn-delete").on("click", function (e) {
        e.preventDefault();
        var form = $(this).parents("form").attr("id");
        swal({
            title: "Você está certo?",
            text: "Uma vez deletado, você não poderá recuperar esse item novamente!",
            icon: "warning",
            buttons: true,
            buttons: ["Cancelar", "Excluir"],
            dangerMode: true,
        }).then((isConfirm) => {
            if (isConfirm) {
                document.getElementById(form).submit();
            } else {
                swal("Este item está salvo!");
            }
        });
    });

    $("#image").on("change", function () {
        var imgPath = $(this)[0].value;
        var ext = imgPath.substring(imgPath.lastIndexOf(".") + 1).toLowerCase();
        if (ext == "gif" || ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "svg")
            readUrl(this);
        else
            alert("Por favor, selecione o arquivo de imagem (jpg, jpeg, png, svg).");
    });

})(jQuery); // End of use strict

function readUrl(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        reader.onload = function (e) {
            $("#preview-image").attr("src", e.target.result);
            $("#remove-image").val(0);
        };
    }
}

function printPage() {
    let body = document.body.cloneNode(true);
    document.querySelectorAll(".no-print").forEach(function (no) {
        no.hidden = true;
    });
    let print = document.querySelector(".print").cloneNode(true);
    document.body.innerHTML = "";
    document.body.appendChild(print);
    window.print();
    document.body.innerHTML = "";
    document.body.appendChild(body);
    location.reload();
}




require("./components/inputMask");
require("./components/select2");
