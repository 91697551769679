$(function () {
    $(".select2").select2({
        language: "pt-BR",
        placeholder: "Selecione...",
        width: "100%",
        theme: "bootstrap4",
    });

    $(".multi-select").bootstrapDualListbox({
        nonSelectedListLabel: "Disponíveis",
        selectedListLabel: "Selecionados",
        filterPlaceHolder: "Filtrar",
        filterTextClear: "Mostrar Todos",
        moveSelectedLabel: "Mover Selecionados",
        moveAllLabel: "Mover Todos",
        removeSelectedLabel: "Remover Selecionado",
        removeAllLabel: "Remover Todos",
        infoText: "Mostrando Todos - {0}",
        infoTextFiltered:
            '<span class="label label-warning">Filtrado</span> {0} DE {1}',
        infoTextEmpty: "Sem Dados",
        moveOnSelect: false,
    });

    var customSettings =
        $(".multi-select").bootstrapDualListbox("getContainer");
    customSettings
        .find(".moveall i")
        .removeClass()
        .addClass("fa fa-angle-double-right")
        .next()
        .remove();
    customSettings
        .find(".move i")
        .removeClass()
        .addClass("fa fa-angle-right")
        .next()
        .remove();
    customSettings
        .find(".removeall i")
        .removeClass()
        .addClass("fa fa-angle-double-left")
        .next()
        .remove();
    customSettings
        .find(".remove i")
        .removeClass()
        .addClass("fa fa-angle-left")
        .next()
        .remove();
});
